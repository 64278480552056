.line {
  height: 15px;
  background-color: #8cb9e2;
  margin-top: 6.1px;
}
.name {
  width: 520px;
}

.spacings {
  padding-bottom: 5px;
}

.score {
  margin-left: 25px;
}

.miniBarProgress {
  height: 100%;
  margin-top: 6.1px;
  position: absolute;
  top: 0rem;
  left: 0rem;
}

.miniBarProgressText {
  height: 100%;
  margin-top: 1.1px;
  position: absolute;
  top: 0rem;
  left: 0rem;
}
.miniBar {
  height: 15px;
  position: relative;
  margin-right: 0.5rem;
}

@media screen and (min-width: 300px) {
  .name {
    text-align: start;
  }
}

@media screen and (min-width: 576px) {
  .name {
    text-align: end;
  }
}

.count_hr {
  height: 9px;
  padding-top: 0;
  top: -5px;
}

.count_number {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  border: 2px solid red;
  border-radius: 50%;
  height: 120px;
  width: 120px;
  text-align: center;
  line-height: 120px;

}

.logotypes {
  width: 1920px;
  padding-bottom: 10px;
  padding-left: 270px;
  padding-right: 270px;
  display: flex;
  justify-content: space-between;
}

.qr {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index:500;
  height: 300px;
}

.logo-1 {
  margin-top: 20px;
  margin-left: 20px;
  width: 531.5px;
  height: 134.75px;
}

.logo-2 {
  margin-top: 20px;
  margin-right: 20px;
  width: auto;
  height: 140.75px;
}

/*
.count_hr {
  height: 9px;
  padding-top: 0;
  top: -5px;
}

.count_number {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  border: 0px solid;
  border-radius: 50%;
  width: 120px;
  text-align: center;

}
*/

.count_text {
  font-family: 'Roboto', sans-serif;
  font-weight: 100;
}

.count_header {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
}

